
import { ref, defineComponent, onMounted, computed, watch } from "vue";
import { Form } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import * as Yup from "yup";
import { useRouter } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from "moment";

export default defineComponent({
  name: "dashboard",
  components: {
    Form,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const submitButton1 = ref<HTMLButtonElement | null>(null);
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
      obtenerReserva();
    });
    const saveChanges = () => {
      if (submitButton1.value) {
        console.log("submitButton1");
        submitButton1.value.setAttribute("data-kt-indicator", "on");
        submitButton1.value!.disabled = true;
        // Activate indicator
        store
          .dispatch(Actions.UPD_RESERVA_CONFIRMAR, store.getters.reservaId)
          .then(() => {
            submitButton1.value?.removeAttribute("data-kt-indicator");
            router.push({ name: "reservation-voucher" });
            /*loading = ref(false);
            Swal.fire({
              text: "El Campania se ha actualizado correctamente.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              router.push({ name: "apps-campanias-listing" });
            });*/
          })
          .catch(() => {
            submitButton1.value?.removeAttribute("data-kt-indicator");
            // eslint-disable-next-line
            submitButton1.value!.disabled = false;

            
            const [error] = Object.keys(store.getters.getReservaErrors);
            Swal.fire({
              text: store.getters.getReservaErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Intentar nuevamente!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
    };
    const currentUser = computed(() => {
      return store.getters.currentUser;
    });
    const currentReserva = computed(() => {
      return store.getters.currentReserva;
    });
    const obtenerReserva = () => {
      store
        .dispatch(Actions.GET_RESERVA, store.getters.reservaId)
        .then(() => {
          loading.value = false;
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getReservaErrors);
        });
    };
    const formatDate = (value) => {
      return moment(value).format("MMM D YYYY");
    };
    const formatHour = (value) => {
      return moment(value).format("LT");
    };
    const formatCurrency = (value) => {
      return value.toLocaleString("es-CL", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: "currency",
        currency: "CLP",
      });
    };
    const imagen = (flota) => {
      if(!flota.segmentoVehiculo)
        return '';
       return `/media/flota/${flota.segmentoVehiculo}/${flota.marcaVehiculo}/${flota.modeloVehiculo.replace("/", "-")}.png`;
   };

    const imageUrlAlt = (event) => {
      event.target.src = "/media/mock/auto3.png";
    };

    const loading = ref(true);
    return {
      loading,
      currentUser,
      submitButton1,
      saveChanges,
      currentReserva,
      formatDate,
      formatCurrency,
      formatHour,
      imagen,
      imageUrlAlt,
    };
  },
});
